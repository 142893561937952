import { ChangeDetectionStrategy, Component, effect, inject, Input, OnChanges, signal, SimpleChanges, untracked } from '@angular/core';
import { GenerateImageTask, GenerateMidjourneyImageTask } from '../../models/generate-image-task.models';
import { ImagingService } from '../../services/imagine.service';
import { GlobalSignalingService } from '../../../../shared/services/signaling/global-signaling.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GenerateImageTaskStatusEnum, PostprocessGenerateImageResultStatusEnum } from '../../models/enumerations';
import { TranslocoService } from '@jsverse/transloco';
import { GenerateMidjourneyImageResult } from '../../models/generate-image-result.models';
import { ViewImageDirective } from '../../../../shared/directives/view-image.directive';


type GenerationStatus = "pending" | "ok" | "error";

@Component({
	selector: 'app-generate-image-task',
	standalone: true,
	imports: [
		NgxSkeletonLoaderModule,
		ViewImageDirective
	],
	templateUrl: './generate-image-task.component.html',
	styleUrl: './generate-image-task.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateImageTaskComponent implements OnChanges {

	private readonly _imagingService = inject(ImagingService);
	private readonly _signalingService = inject(GlobalSignalingService);
	private readonly _translateService = inject(TranslocoService);

	statusText = signal<string | undefined>(undefined);
	generalStatus = signal<GenerationStatus>('pending');
	task = signal<GenerateImageTask | undefined>(undefined);

	@Input({ required: true, alias: 'task' }) taskInput!: GenerateImageTask;

	constructor() {
		effect(() => {
			const task = this._signalingService.generateImageTaskStatus();
			const result = this._signalingService.postprocessGenerateImageResultStatus();
			if (task?.generateImageTaskId !== this.taskInput.id && result?.generateImageTaskId !== this.taskInput.id) {
				return;
			}

			const taskStatus = task?.generateImageTaskId === this.taskInput.id ? task.statusId : undefined;
			const postprocStatus = result?.generateImageTaskId === this.taskInput.id ? result.statusId : undefined;
			const text = this.statusToText(taskStatus, postprocStatus);
			const status = this.statusToGeneral(taskStatus, postprocStatus);
			this.statusText.set(text);
			this.generalStatus.set(status);

			if (postprocStatus === PostprocessGenerateImageResultStatusEnum.Succeeded) {
				this._imagingService.getGenerateImageTaskById(this.taskInput.id).subscribe(v => {
					this.task.set(v);
				})
			}

		}, {
			allowSignalWrites: true
		});
	}

	prompt(task: GenerateImageTask): string {
		if (task instanceof GenerateMidjourneyImageTask) {
			return (task.result as GenerateMidjourneyImageResult).finalPrompt ?? task.prompt;
		}
		return task.prompt;
	}

	statusToText(taskStatusId?: GenerateImageTaskStatusEnum, postprocessStatusId?: PostprocessGenerateImageResultStatusEnum): string {
		if (!postprocessStatusId) {
			if (taskStatusId === GenerateImageTaskStatusEnum.Initial) {
				return this._translateService.translate("imaging.components.generateImageTask.taskStatus.planningGeneration");
			}
			if (taskStatusId === GenerateImageTaskStatusEnum.Generating) {
				return this._translateService.translate("imaging.components.generateImageTask.taskStatus.generating");
			}
			if (taskStatusId === GenerateImageTaskStatusEnum.Failed) {
				return this._translateService.translate("imaging.components.generateImageTask.taskStatus.generationFailed");
			}
			if (taskStatusId === GenerateImageTaskStatusEnum.Succeeded) {
				return this._translateService.translate("imaging.components.generateImageTask.taskStatus.generationSucceeded");
			}
		}
		else {
			if (postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Initial) {
				return this._translateService.translate("imaging.components.generateImageTask.taskStatus.planningPostprocess");
			}
			if (postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Processing) {
				return this._translateService.translate("imaging.components.generateImageTask.taskStatus.postprocessing");
			}
			if (postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Failed) {
				return this._translateService.translate("imaging.components.generateImageTask.taskStatus.postprocessFailed");
			}
			if (postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Succeeded) {
				return this._translateService.translate("imaging.components.generateImageTask.taskStatus.postprocessSucceeded");
			}
		}
		return "Хер пойми";
	}

	statusToGeneral(taskStatusId?: GenerateImageTaskStatusEnum, postprocessStatusId?: PostprocessGenerateImageResultStatusEnum): GenerationStatus {
		if (!postprocessStatusId) {
			if (taskStatusId === GenerateImageTaskStatusEnum.Failed) {
				return "error";
			}
		}
		else {
			if (postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Succeeded) {
				return "ok";
			}
		}
		return "pending";
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.task.set(this.taskInput);
		this.statusText.set(this.statusToText(this.taskInput.statusId, this.taskInput.result?.postprocessStatusId));
		this.generalStatus.set(this.statusToGeneral(this.taskInput.statusId, this.taskInput.result?.postprocessStatusId));
	}
}
