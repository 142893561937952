export enum TargetStyleEnum {
	Undefined = 0,
	Photorealistic = 1,
	Illustration = 2
}

export interface EnhancePromptRequest {
	prompt: string;
	targetStyleId: TargetStyleEnum;
}
