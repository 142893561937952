import { inject, Injectable } from '@angular/core';
import { EnhancePromptRequest } from '../models/magic-prompt.models';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '../../../core/models/api/api.models';
import { WORKSPACE_API_URL_PREFIX } from '../../../shared/constants/api.constants';

@Injectable({
	providedIn: 'root'
})
export class AssistantsService {

	private readonly _http = inject(HttpClient);

	enhancePrompt(request: EnhancePromptRequest): Observable<string> {
		const body = request;
		return this._http.post<ApiResponse<string>>(`${WORKSPACE_API_URL_PREFIX}/api/assistants/enhance-prompt`, body).pipe(
			map(v => v.data)
		);
	}
}
