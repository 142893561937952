import { inject, Injectable, OnDestroy, Signal, signal } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IConfigurationService } from '../../../core/services/configuration/configuration.service';
import * as SignalR from '@microsoft/signalr';
import { combineLatest, lastValueFrom, map, switchMap } from 'rxjs';
import { GenerateImageTaskStatus, PostprocessGenerateImageResultStatus } from '../../../features/imaging/models/signaling';

@Injectable({
	providedIn: 'root'
})
export class GlobalSignalingService implements OnDestroy {

	private readonly _oidcService = inject(OidcSecurityService);
	private readonly _configService = inject(IConfigurationService);

	private _hubConnection: HubConnection | null = null;

	private readonly _generateImageTaskStatus = signal<GenerateImageTaskStatus | undefined>(undefined);
	public readonly generateImageTaskStatus = this._generateImageTaskStatus.asReadonly();

	private readonly _postprocessGenerateImageResultStatus = signal<PostprocessGenerateImageResultStatus | undefined>(undefined);
	public readonly postprocessGenerateImageResultStatus = this._postprocessGenerateImageResultStatus.asReadonly();

	private registerListeners() {
		if (!this._hubConnection) {
			throw new Error("HubConnection not initialized");
		}
		this._hubConnection.on('OnGenerateImageTaskStatus', (arg: GenerateImageTaskStatus) => {
			this._generateImageTaskStatus.set(arg);
		});
		this._hubConnection.on('OnPostprocessGenerateImageResultStatus', (arg: PostprocessGenerateImageResultStatus) => {
			this._postprocessGenerateImageResultStatus.set(arg);
		});
	}

	async initialize() {
		const connection$ = combineLatest([
			this._configService.getConfiguration(),
			this._oidcService.checkAuth().pipe(
				switchMap(() => this._oidcService.getAccessToken())
			)
		]).pipe(
			map(([config, token]) => ({ config, token })),
			map(v => {
				if (!v.token) {
					return null;
				}
				const connection = new SignalR.HubConnectionBuilder()
					.withUrl(`${v.config.endpoints.workspaceApiRoot}/hubs/global`, { accessTokenFactory: () => v.token })
					.withAutomaticReconnect()
					.build();
				return connection;
			})
		);

		this._hubConnection = await lastValueFrom(connection$);

		if (!this._hubConnection) {
			return;
		}

		this.registerListeners();

		try {
			await this._hubConnection.start();
		}
		catch (error) {
			console.log(error);
		}
	}

	async ngOnDestroy() {
		await this.disconnect();
	}

	async disconnect() {
		if (!this._hubConnection) {
			return;
		}
		await this._hubConnection.stop();
	}
}
