import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AssistantsService } from '../../services/assistants.service';
import { EnhancePromptRequest, TargetStyleEnum } from '../../models/magic-prompt.models';
import { TranslocoModule } from '@jsverse/transloco';
import { finalize } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
	selector: 'app-magic-prompt',
	standalone: true,
	imports: [
		NgIf,
		MatButtonModule,
		MatMenuModule,
		TranslocoModule,
		MatProgressSpinnerModule
	],
	templateUrl: './magic-prompt.component.html',
	styleUrl: './magic-prompt.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MagicPromptComponent {

	private readonly _assistantsService = inject(AssistantsService);

	prompt = input<string | null>();
	enhanced = output<string>();

	TargetStyleEnum = TargetStyleEnum;

	disabled = computed(() => {
		return (this.prompt()?.length ?? 0) === 0 || this.loading();
	});

	loading = signal(false);

	enhancePrompt(targetStyleId: TargetStyleEnum) {
		const request = <EnhancePromptRequest>{
			prompt: this.prompt(),
			targetStyleId
		};
		this.loading.set(true);
		this._assistantsService.enhancePrompt(request).pipe(
			finalize(() => {
				this.loading.set(false);
			})
		).subscribe(v => {
			this.enhanced.emit(v);
		});
	}
}
